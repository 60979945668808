import E from 'wangeditor';
class wEditor {
    constructor(ele, url,menus = [], fn) {
        this.editor = new E(ele);
        this.uploadUrl = url;
        // this.editor.customConfig.emotions = [
        //     {
        //         // tab 的标题
        //         title: '默认',
        //         // type -> 'emoji' / 'image'
        //         type: 'image',
        //         // content -> 数组
        //         content: [
        //             {
        //                 alt: '[微笑]',
        //                 src: require("../assets/emoticons/0.gif")
        //             },
        //             {
        //                 alt: '[瘪嘴]',
        //                 src: require("../assets/emoticons/1.gif")
        //             },
        //             {
        //                 alt: '[色]',
        //                 src: require("../assets/emoticons/2.gif")
        //             },
        //             {
        //                 alt: '[发呆]',
        //                 src: require("../assets/emoticons/3.gif")
        //             },
        //             {
        //                 alt: '[得意]',
        //                 src: require("../assets/emoticons/4.gif")
        //             },
        //             {
        //                 alt: '[流泪]',
        //                 src: require("../assets/emoticons/5.gif")
        //             },
        //             {
        //                 alt: '[害羞]',
        //                 src: require("../assets/emoticons/6.gif")
        //             },
        //             {
        //                 alt: '[闭嘴]',
        //                 src: require("../assets/emoticons/7.gif")
        //             },
        //             {
        //                 alt: '[睡]',
        //                 src: require("../assets/emoticons/8.gif")
        //             },
        //             {
        //                 alt: '[大哭]',
        //                 src: require("../assets/emoticons/9.gif")
        //             },
        //             {
        //                 alt: '[尴尬]',
        //                 src: require("../assets/emoticons/10.gif")
        //             },
        //             {
        //                 alt: '[发怒]',
        //                 src: require("../assets/emoticons/11.gif")
        //             },
        //             {
        //                 alt: '[调皮]',
        //                 src: require("../assets/emoticons/12.gif")
        //             },
        //             {
        //                 alt: '[呲牙]',
        //                 src: require("../assets/emoticons/13.gif")
        //             },
        //             {
        //                 alt: '[惊讶]',
        //                 src: require("../assets/emoticons/14.gif")
        //             },
        //             {
        //                 alt: '[难过]',
        //                 src: require("../assets/emoticons/15.gif")
        //             },
        //             {
        //                 alt: '[酷]',
        //                 src: require("../assets/emoticons/16.gif")
        //             },
        //             {
        //                 alt: '[冷汗]',
        //                 src: require("../assets/emoticons/17.gif")
        //             },
        //             {
        //                 alt: '[抓狂]',
        //                 src: require("../assets/emoticons/18.gif")
        //             },
        //             {
        //                 alt: '[吐]',
        //                 src: require("../assets/emoticons/19.gif")
        //             },
        //             {
        //                 alt: '[偷笑]',
        //                 src: require("../assets/emoticons/20.gif")
        //             },
        //             {
        //                 alt: '[愉快]',
        //                 src: require("../assets/emoticons/21.gif")
        //             },
        //             {
        //                 alt: '[白眼]',
        //                 src: require("../assets/emoticons/22.gif")
        //             },
        //             {
        //                 alt: '[傲慢]',
        //                 src: require("../assets/emoticons/23.gif")
        //             },
        //             {
        //                 alt: '[饥饿]',
        //                 src: require("../assets/emoticons/24.gif")
        //             },
        //             {
        //                 alt: '[困]',
        //                 src: require("../assets/emoticons/25.gif")
        //             },
        //             {
        //                 alt: '[惊恐]',
        //                 src: require("../assets/emoticons/26.gif")
        //             },
        //             {
        //                 alt: '[流汗]',
        //                 src: require("../assets/emoticons/27.gif")
        //             },
        //             {
        //                 alt: '[憨笑]',
        //                 src: require("../assets/emoticons/28.gif")
        //             },
        //             {
        //                 alt: '[悠闲]',
        //                 src: require("../assets/emoticons/29.gif")
        //             },
        //             {
        //                 alt: '[奋斗]',
        //                 src: require("../assets/emoticons/30.gif")
        //             },
        //             {
        //                 alt: '[咒骂]',
        //                 src: require("../assets/emoticons/31.gif")
        //             },
        //             {
        //                 alt: '[疑问]',
        //                 src: require("../assets/emoticons/32.gif")
        //             },
        //             {
        //                 alt: '[嘘]',
        //                 src: require("../assets/emoticons/33.gif")
        //             },
        //             {
        //                 alt: '[晕]',
        //                 src: require("../assets/emoticons/34.gif")
        //             },
        //             {
        //                 alt: '[疯了]',
        //                 src: require("../assets/emoticons/35.gif")
        //             },
        //             {
        //                 alt: '[哀]',
        //                 src: require("../assets/emoticons/36.gif")
        //             },
        //             {
        //                 alt: '[骷髅]',
        //                 src: require("../assets/emoticons/37.gif")
        //             },
        //             {
        //                 alt: '[敲打]',
        //                 src: require("../assets/emoticons/38.gif")
        //             },
        //             {
        //                 alt: '[再见]',
        //                 src: require("../assets/emoticons/39.gif")
        //             },
        //             {
        //                 alt: '[擦汗]',
        //                 src: require("../assets/emoticons/40.gif")
        //             },
        //             {
        //                 alt: '[抠鼻]',
        //                 src: require("../assets/emoticons/41.gif")
        //             },
        //             {
        //                 alt: '[鼓掌]',
        //                 src: require("../assets/emoticons/42.gif")
        //             },
        //             {
        //                 alt: '[糗大了]',
        //                 src: require("../assets/emoticons/43.gif")
        //             },
        //             {
        //                 alt: '[坏笑]',
        //                 src: require("../assets/emoticons/44.gif")
        //             },
        //             {
        //                 alt: '[左哼哼]',
        //                 src: require("../assets/emoticons/45.gif")
        //             },
        //             {
        //                 alt: '[右哼哼]',
        //                 src: require("../assets/emoticons/46.gif")
        //             },
        //             {
        //                 alt: '[哈欠]',
        //                 src: require("../assets/emoticons/47.gif")
        //             },
        //             {
        //                 alt: '[鄙视]',
        //                 src: require("../assets/emoticons/48.gif")
        //             },
        //             {
        //                 alt: '[委屈]',
        //                 src: require("../assets/emoticons/49.gif")
        //             },
        //             {
        //                 alt: '[快哭了]',
        //                 src: require("../assets/emoticons/50.gif")
        //             },
        //             {
        //                 alt: '[阴险]',
        //                 src: require("../assets/emoticons/51.gif")
        //             },
        //             {
        //                 alt: '[亲亲]',
        //                 src: require("../assets/emoticons/52.gif")
        //             },
        //             {
        //                 alt: '[吓]',
        //                 src: require("../assets/emoticons/53.gif")
        //             },
        //             {
        //                 alt: '[可怜]',
        //                 src: require("../assets/emoticons/54.gif")
        //             },
        //             {
        //                 alt: '[菜刀]',
        //                 src: require("../assets/emoticons/55.gif")
        //             },
        //             {
        //                 alt: '[西瓜]',
        //                 src: require("../assets/emoticons/56.gif")
        //             },
        //             {
        //                 alt: '[啤酒]',
        //                 src: require("../assets/emoticons/57.gif")
        //             },
        //             {
        //                 alt: '[篮球]',
        //                 src: require("../assets/emoticons/58.gif")
        //             },
        //             {
        //                 alt: '[乒乓]',
        //                 src: require("../assets/emoticons/59.gif")
        //             },
        //             {
        //                 alt: '[咖啡]',
        //                 src: require("../assets/emoticons/60.gif")
        //             },
        //             {
        //                 alt: '[饭]',
        //                 src: require("../assets/emoticons/61.gif")
        //             },
        //             {
        //                 alt: '[猪头]',
        //                 src: require("../assets/emoticons/62.gif")
        //             },
        //             {
        //                 alt: '[玫瑰]',
        //                 src: require("../assets/emoticons/63.gif")
        //             },
        //             {
        //                 alt: '[凋谢]',
        //                 src: require("../assets/emoticons/64.gif")
        //             },
        //             {
        //                 alt: '[嘴唇]',
        //                 src: require("../assets/emoticons/65.gif")
        //             },
        //             {
        //                 alt: '[爱心]',
        //                 src: require("../assets/emoticons/66.gif")
        //             },
        //             {
        //                 alt: '[心碎]',
        //                 src: require("../assets/emoticons/67.gif")
        //             },
        //             {
        //                 alt: '[蛋糕]',
        //                 src: require("../assets/emoticons/68.gif")
        //             },
        //             {
        //                 alt: '[闪电]',
        //                 src: require("../assets/emoticons/69.gif")
        //             },
        //             {
        //                 alt: '[炸弹]',
        //                 src: require("../assets/emoticons/70.gif")
        //             },
        //             {
        //                 alt: '[刀]',
        //                 src: require("../assets/emoticons/71.gif")
        //             },
        //             {
        //                 alt: '[足球]',
        //                 src: require("../assets/emoticons/72.gif")
        //             },
        //             {
        //                 alt: '[瓢虫]',
        //                 src: require("../assets/emoticons/73.gif")
        //             },
        //             {
        //                 alt: '[便便]',
        //                 src: require("../assets/emoticons/74.gif")
        //             },
        //             {
        //                 alt: '[月亮]',
        //                 src: require("../assets/emoticons/75.gif")
        //             },
        //             {
        //                 alt: '[太阳]',
        //                 src: require("../assets/emoticons/76.gif")
        //             },
        //             {
        //                 alt: '[礼物]',
        //                 src: require("../assets/emoticons/77.gif")
        //             },
        //             {
        //                 alt: '[拥抱]',
        //                 src: require("../assets/emoticons/78.gif")
        //             },
        //             {
        //                 alt: '[强]',
        //                 src: require("../assets/emoticons/79.gif")
        //             },
        //             {
        //                 alt: '[弱]',
        //                 src: require("../assets/emoticons/80.gif")
        //             },
        //             {
        //                 alt: '[握手]',
        //                 src: require("../assets/emoticons/81.gif")
        //             },
        //             {
        //                 alt: '[胜利]',
        //                 src: require("../assets/emoticons/82.gif")
        //             },
        //             {
        //                 alt: '[抱拳]',
        //                 src: require("../assets/emoticons/83.gif")
        //             },
        //             {
        //                 alt: '[勾引]',
        //                 src: require("../assets/emoticons/84.gif")
        //             },
        //             {
        //                 alt: '[拳头]',
        //                 src: require("../assets/emoticons/85.gif")
        //             },
        //             {
        //                 alt: '[差劲]',
        //                 src: require("../assets/emoticons/86.gif")
        //             },
        //             {
        //                 alt: '[爱你]',
        //                 src: require("../assets/emoticons/87.gif")
        //             },
        //             {
        //                 alt: '[NO]',
        //                 src: require("../assets/emoticons/88.gif")
        //             },
        //             {
        //                 alt: '[OK]',
        //                 src: require("../assets/emoticons/89.gif")
        //             },
        //             {
        //                 alt: '[爱情]',
        //                 src: require("../assets/emoticons/90.gif")
        //             },
        //             {
        //                 alt: '[飞吻]',
        //                 src: require("../assets/emoticons/91.gif")
        //             },
        //             {
        //                 alt: '[跳跳]',
        //                 src: require("../assets/emoticons/92.gif")
        //             },
        //             {
        //                 alt: '[发抖]',
        //                 src: require("../assets/emoticons/93.gif")
        //             },
        //             {
        //                 alt: '[怄火]',
        //                 src: require("../assets/emoticons/94.gif")
        //             },
        //             {
        //                 alt: '[转圈]',
        //                 src: require("../assets/emoticons/95.gif")
        //             },
        //             {
        //                 alt: '[磕头]',
        //                 src: require("../assets/emoticons/96.gif")
        //             },
        //             {
        //                 alt: '[回头]',
        //                 src: require("../assets/emoticons/97.gif")
        //             },
        //             {
        //                 alt: '[跳绳]',
        //                 src: require("../assets/emoticons/98.gif")
        //             },
        //             {
        //                 alt: '[投降]',
        //                 src: require("../assets/emoticons/99.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/100.gif")
        //             },
        //             {
        //                 alt: '[乱舞]',
        //                 src: require("../assets/emoticons/101.gif")
        //             },
        //             {
        //                 alt: '[献吻]',
        //                 src: require("../assets/emoticons/102.gif")
        //             },
        //             {
        //                 alt: '[左太极]',
        //                 src: require("../assets/emoticons/103.gif")
        //             },
        //             {
        //                 alt: '[右太极]',
        //                 src: require("../assets/emoticons/104.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/105.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/106.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/107.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/108.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/109.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/110.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/111.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/112.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/113.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/114.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/115.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/116.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/117.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/118.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/119.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/120.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/121.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/122.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/123.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/124.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/125.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/126.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/127.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/128.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/129.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/130.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/131.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/132.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/133.gif")
        //             },
        //             {
        //                 alt: '[激动]',
        //                 src: require("../assets/emoticons/134.gif")
        //             },
        //             {
        //                 alt: '[草泥马]',
        //                 src: require("../assets/emoticons/135.gif")
        //             },
        //             {
        //                 alt: '[神马]',
        //                 src: require("../assets/emoticons/136.gif")
        //             },
        //             {
        //                 alt: '[浮云]',
        //                 src: require("../assets/emoticons/137.gif")
        //             },
        //             {
        //                 alt: '[给力]',
        //                 src: require("../assets/emoticons/138.gif")
        //             },
        //             {
        //                 alt: '[围观]',
        //                 src: require("../assets/emoticons/139.gif")
        //             },
        //             {
        //                 alt: '[威武]',
        //                 src: require("../assets/emoticons/140.gif")
        //             },
        //             {
        //                 alt: '[熊猫]',
        //                 src: require("../assets/emoticons/141.gif")
        //             },
        //             {
        //                 alt: '[兔子]',
        //                 src: require("../assets/emoticons/142.gif")
        //             },
        //             {
        //                 alt: '[奥特曼]',
        //                 src: require("../assets/emoticons/143.gif")
        //             },
        //             {
        //                 alt: '[囧]',
        //                 src: require("../assets/emoticons/144.gif")
        //             },
        //             {
        //                 alt: '[互粉]',
        //                 src: require("../assets/emoticons/145.gif")
        //             },
        //             {
        //                 alt: '[礼物]',
        //                 src: require("../assets/emoticons/146.gif")
        //             },
        //             {
        //                 alt: '[呵呵]',
        //                 src: require("../assets/emoticons/147.gif")
        //             },
        //             {
        //                 alt: '[嘿嘿]',
        //                 src: require("../assets/emoticons/148.gif")
        //             },
        //             {
        //                 alt: '[哈哈]',
        //                 src: require("../assets/emoticons/149.gif")
        //             },
        //             {
        //                 alt: '[可爱]',
        //                 src: require("../assets/emoticons/150.gif")
        //             },
        //             {
        //                 alt: '[可怜]',
        //                 src: require("../assets/emoticons/151.gif")
        //             },
        //             {
        //                 alt: '[挖鼻屎]',
        //                 src: require("../assets/emoticons/152.gif")
        //             },
        //             {
        //                 alt: '[吃惊]',
        //                 src: require("../assets/emoticons/153.gif")
        //             },
        //             {
        //                 alt: '[害羞]',
        //                 src: require("../assets/emoticons/154.gif")
        //             },
        //             {
        //                 alt: '[挤眼]',
        //                 src: require("../assets/emoticons/155.gif")
        //             },
        //             {
        //                 alt: '[闭嘴]',
        //                 src: require("../assets/emoticons/156.gif")
        //             },
        //             {
        //                 alt: '[鄙视]',
        //                 src: require("../assets/emoticons/157.gif")
        //             },
        //             {
        //                 alt: '[爱你]',
        //                 src: require("../assets/emoticons/158.gif")
        //             },
        //             {
        //                 alt: '[泪]',
        //                 src: require("../assets/emoticons/159.gif")
        //             },
        //             {
        //                 alt: '[偷笑]',
        //                 src: require("../assets/emoticons/160.gif")
        //             },
        //             {
        //                 alt: '[亲亲]',
        //                 src: require("../assets/emoticons/161.gif")
        //             },
        //             {
        //                 alt: '[生病]',
        //                 src: require("../assets/emoticons/162.gif")
        //             },
        //             {
        //                 alt: '[太开心]',
        //                 src: require("../assets/emoticons/163.gif")
        //             },
        //             {
        //                 alt: '[懒得理你]',
        //                 src: require("../assets/emoticons/164.gif")
        //             },
        //             {
        //                 alt: '[右哼哼]',
        //                 src: require("../assets/emoticons/165.gif")
        //             },
        //             {
        //                 alt: '[左哼哼]',
        //                 src: require("../assets/emoticons/166.gif")
        //             },
        //             {
        //                 alt: '[嘘]',
        //                 src: require("../assets/emoticons/167.gif")
        //             },
        //             {
        //                 alt: '[衰]',
        //                 src: require("../assets/emoticons/168.gif")
        //             },
        //             {
        //                 alt: '[委屈]',
        //                 src: require("../assets/emoticons/169.gif")
        //             },
        //             {
        //                 alt: '[吐]',
        //                 src: require("../assets/emoticons/170.gif")
        //             },
        //             {
        //                 alt: '[打哈欠]',
        //                 src: require("../assets/emoticons/171.gif")
        //             },
        //             {
        //                 alt: '[抱抱]',
        //                 src: require("../assets/emoticons/172.gif")
        //             },
        //             {
        //                 alt: '[怒]',
        //                 src: require("../assets/emoticons/173.gif")
        //             },
        //             {
        //                 alt: '[疑问]',
        //                 src: require("../assets/emoticons/174.gif")
        //             },
        //             {
        //                 alt: '[馋嘴]',
        //                 src: require("../assets/emoticons/175.gif")
        //             },
        //             {
        //                 alt: '[拜拜]',
        //                 src: require("../assets/emoticons/176.gif")
        //             },
        //             {
        //                 alt: '[思考]',
        //                 src: require("../assets/emoticons/177.gif")
        //             },
        //             {
        //                 alt: '[汗]',
        //                 src: require("../assets/emoticons/178.gif")
        //             },
        //             {
        //                 alt: '[困]',
        //                 src: require("../assets/emoticons/179.gif")
        //             },
        //             {
        //                 alt: '[睡觉]',
        //                 src: require("../assets/emoticons/180.gif")
        //             },
        //             {
        //                 alt: '[钱]',
        //                 src: require("../assets/emoticons/181.gif")
        //             },
        //             {
        //                 alt: '[失望]',
        //                 src: require("../assets/emoticons/182.gif")
        //             },
        //             {
        //                 alt: '[酷]',
        //                 src: require("../assets/emoticons/183.gif")
        //             },
        //             {
        //                 alt: '[花心]',
        //                 src: require("../assets/emoticons/184.gif")
        //             },
        //             {
        //                 alt: '[哼]',
        //                 src: require("../assets/emoticons/185.gif")
        //             },
        //             {
        //                 alt: '[鼓掌]',
        //                 src: require("../assets/emoticons/186.gif")
        //             },
        //             {
        //                 alt: '[晕]',
        //                 src: require("../assets/emoticons/187.gif")
        //             },
        //             {
        //                 alt: '[悲伤]',
        //                 src: require("../assets/emoticons/188.gif")
        //             },
        //             {
        //                 alt: '[抓狂]',
        //                 src: require("../assets/emoticons/189.gif")
        //             },
        //             {
        //                 alt: '[黑线]',
        //                 src: require("../assets/emoticons/190.gif")
        //             },
        //             {
        //                 alt: '[阴险]',
        //                 src: require("../assets/emoticons/191.gif")
        //             },
        //             {
        //                 alt: '[怒骂]',
        //                 src: require("../assets/emoticons/192.gif")
        //             },
        //             {
        //                 alt: '[心]',
        //                 src: require("../assets/emoticons/193.gif")
        //             },
        //             {
        //                 alt: '[伤心]',
        //                 src: require("../assets/emoticons/194.gif")
        //             },
        //             {
        //                 alt: '[猪头]',
        //                 src: require("../assets/emoticons/195.gif")
        //             },
        //             {
        //                 alt: '[ok]',
        //                 src: require("../assets/emoticons/196.gif")
        //             },
        //             {
        //                 alt: '[耶]',
        //                 src: require("../assets/emoticons/197.gif")
        //             },
        //             {
        //                 alt: '[good]',
        //                 src: require("../assets/emoticons/198.gif")
        //             },
        //             {
        //                 alt: '[不要]',
        //                 src: require("../assets/emoticons/199.gif")
        //             },
        //             {
        //                 alt: '[赞]',
        //                 src: require("../assets/emoticons/200.gif")
        //             },
        //             {
        //                 alt: '[来]',
        //                 src: require("../assets/emoticons/201.gif")
        //             },
        //             {
        //                 alt: '[弱]',
        //                 src: require("../assets/emoticons/202.gif")
        //             },
        //             {
        //                 alt: '[蜡烛]',
        //                 src: require("../assets/emoticons/203.gif")
        //             },
        //             {
        //                 alt: '[蛋糕]',
        //                 src: require("../assets/emoticons/204.gif")
        //             },
        //             {
        //                 alt: '[钟]',
        //                 src: require("../assets/emoticons/205.gif")
        //             },
        //             {
        //                 alt: '[话筒]',
        //                 src: require("../assets/emoticons/206.gif")
        //             },
        //             {
        //                 alt: '[我们喵星人]',
        //                 src: require("../assets/emoticons/207.gif")
        //             },
        //             {
        //                 alt: '[就是这么拽]',
        //                 src: require("../assets/emoticons/208.gif")
        //             },
        //             {
        //                 alt: '[可萌]',
        //                 src: require("../assets/emoticons/209.gif")
        //             },
        //             {
        //                 alt: '[可贱]',
        //                 src: require("../assets/emoticons/210.gif")
        //             },
        //             {
        //                 alt: '[可风骚]',
        //                 src: require("../assets/emoticons/211.gif")
        //             },
        //             {
        //                 alt: '[还那么可爱]',
        //                 src: require("../assets/emoticons/212.gif")
        //             },
        //             {
        //                 alt: '[后宫佳丽]',
        //                 src: require("../assets/emoticons/213.gif")
        //             },
        //             {
        //                 alt: '[三千人]',
        //                 src: require("../assets/emoticons/214.gif")
        //             },
        //             {
        //                 alt: '[谁有我销魂]',
        //                 src: require("../assets/emoticons/215.gif")
        //             },
        //             {
        //                 alt: '[我不许你减肥]',
        //                 src: require("../assets/emoticons/216.jpg")
        //             },
        //             {
        //                 alt: '[不要走好吗]',
        //                 src: require("../assets/emoticons/217.gif")
        //             },
        //             {
        //                 alt: '[敲]',
        //                 src: require("../assets/emoticons/218.gif")
        //             },
        //             {
        //                 alt: '[我有酒，你有故事吗]',
        //                 src: require("../assets/emoticons/219.gif")
        //             },
        //             {
        //                 alt: '[喷火]',
        //                 src: require("../assets/emoticons/220.gif")
        //             },
        //             {
        //                 alt: '[no]',
        //                 src: require("../assets/emoticons/221.gif")
        //             },
        //             {
        //                 alt: '[垃圾语言]',
        //                 src: require("../assets/emoticons/222.gif")
        //             },
        //             {
        //                 alt: '[小哥哥快来]',
        //                 src: require("../assets/emoticons/223.gif")
        //             },
        //             {
        //                 alt: '[请放心]',
        //                 src: require("../assets/emoticons/224.gif")
        //             },
        //             {
        //                 alt: '[gun]',
        //                 src: require("../assets/emoticons/225.gif")
        //             },
        //             {
        //                 alt: '[起床了吗]',
        //                 src: require("../assets/emoticons/226.gif")
        //             },
        //             {
        //                 alt: '[你是傻逼]',
        //                 src: require("../assets/emoticons/227.gif")
        //             },
        //             {
        //                 alt: '[这是飞一样的感觉]',
        //                 src: require("../assets/emoticons/228.gif")
        //             },
        //             {
        //                 alt: '[啊啊啊啊]',
        //                 src: require("../assets/emoticons/229.gif")
        //             },
        //             {
        //                 alt: '[没有不存在的]',
        //                 src: require("../assets/emoticons/230.gif")
        //             },
        //             {
        //                 alt: '[哼]',
        //                 src: require("../assets/emoticons/231.gif")
        //             },
        //             {
        //                 alt: '[我能怎么办]',
        //                 src: require("../assets/emoticons/232.gif")
        //             },
        //             {
        //                 alt: '[不可以]',
        //                 src: require("../assets/emoticons/233.gif")
        //             },
        //             {
        //                 alt: '[小场面别慌]',
        //                 src: require("../assets/emoticons/234.gif")
        //             },
        //             {
        //                 alt: '[没事啊]',
        //                 src: require("../assets/emoticons/235.gif")
        //             },
        //             {
        //                 alt: '[闻脚]',
        //                 src: require("../assets/emoticons/236.gif")
        //             },
        //             {
        //                 alt: '[打扰了告辞]',
        //                 src: require("../assets/emoticons/237.gif")
        //             },
        //             {
        //                 alt: '[鼓掌]',
        //                 src: require("../assets/emoticons/238.gif")
        //             },
        //             {
        //                 alt: '[爱你看箭]',
        //                 src: require("../assets/emoticons/239.gif")
        //             },
        //             {
        //                 alt: '[假装淡定]',
        //                 src: require("../assets/emoticons/240.gif")
        //             },
        //             {
        //                 alt: '[群聊]',
        //                 src: require("../assets/emoticons/241.gif")
        //             },
        //             {
        //                 alt: '[打]',
        //                 src: require("../assets/emoticons/242.gif")
        //             },
        //             {
        //                 alt: '[砸电脑]',
        //                 src: require("../assets/emoticons/243.gif")
        //             },
        //             {
        //                 alt: '[小老弟]',
        //                 src: require("../assets/emoticons/244.gif")
        //             },
        //             {
        //                 alt: '[儿子真乖]',
        //                 src: require("../assets/emoticons/245.gif")
        //             },
        //             {
        //                 alt: '[我不会啊]',
        //                 src: require("../assets/emoticons/246.gif")
        //             },
        //             {
        //                 alt: '[好的老大]',
        //                 src: require("../assets/emoticons/247.gif")
        //             },
        //             {
        //                 alt: '[没毛病]',
        //                 src: require("../assets/emoticons/248.gif")
        //             },
        //             {
        //                 alt: '[抿嘴]',
        //                 src: require("../assets/emoticons/249.gif")
        //             },
        //             {
        //                 alt: '[打瞌睡]',
        //                 src: require("../assets/emoticons/250.gif")
        //             },
        //             {
        //                 alt: '[滴]',
        //                 src: require("../assets/emoticons/251.gif")
        //             },
        //             {
        //                 alt: '[找人打你]',
        //                 src: require("../assets/emoticons/252.gif")
        //             },
        //             {
        //                 alt: '[别说话喝酒]',
        //                 src: require("../assets/emoticons/253.gif")
        //             },
        //             {
        //                 alt: '[打屁股]',
        //                 src: require("../assets/emoticons/254.gif")
        //             },
        //             {
        //                 alt: '[你谁阿]',
        //                 src: require("../assets/emoticons/255.gif")
        //             },
        //             {
        //                 alt: '[收到]',
        //                 src: require("../assets/emoticons/256.gif")
        //             },
        //             {
        //                 alt: '[呸渣渣]',
        //                 src: require("../assets/emoticons/257.gif")
        //             },
        //             {
        //                 alt: '[一车人]',
        //                 src: require("../assets/emoticons/258.gif")
        //             },
        //             {
        //                 alt: '[你是坏人]',
        //                 src: require("../assets/emoticons/259.gif")
        //             },
        //             {
        //                 alt: '[吃馒头]',
        //                 src: require("../assets/emoticons/260.gif")
        //             },
        //             {
        //                 alt: '[哦]',
        //                 src: require("../assets/emoticons/261.gif")
        //             },
        //             {
        //                 alt: '[对只有你配吃屎]',
        //                 src: require("../assets/emoticons/262.gif")
        //             },
        //             {
        //                 alt: '[小老鼠]',
        //                 src: require("../assets/emoticons/263.gif")
        //             },
        //             {
        //                 alt: '[老大爷]',
        //                 src: require("../assets/emoticons/264.gif")
        //             },
        //             {
        //                 alt: '[伸懒腰]',
        //                 src: require("../assets/emoticons/265.gif")
        //             },
        //             {
        //                 alt: '[吐槽]',
        //                 src: require("../assets/emoticons/266.gif")
        //             },
        //             {
        //                 alt: '[多喝热水]',
        //                 src: require("../assets/emoticons/267.gif")
        //             },
        //             {
        //                 alt: '[腿麻了]',
        //                 src: require("../assets/emoticons/268.gif")
        //             },
        //             {
        //                 alt: '[摇脚]',
        //                 src: require("../assets/emoticons/269.gif")
        //             },
        //             {
        //                 alt: '[有种你不要动]',
        //                 src: require("../assets/emoticons/270.gif")
        //             },
        //             {
        //                 alt: '[我什么都不知道]',
        //                 src: require("../assets/emoticons/271.gif")
        //             },
        //             {
        //                 alt: '[困]',
        //                 src: require("../assets/emoticons/272.gif")
        //             },
        //             {
        //                 alt: '[优秀]',
        //                 src: require("../assets/emoticons/273.gif")
        //             },
        //             {
        //                 alt: '[太吓人了]',
        //                 src: require("../assets/emoticons/274.gif")
        //             },
        //             {
        //                 alt: '[拉屎]',
        //                 src: require("../assets/emoticons/275.gif")
        //             },
        //             {
        //                 alt: '[绝望]',
        //                 src: require("../assets/emoticons/276.gif")
        //             },
        //             {
        //                 alt: '[没有了]',
        //                 src: require("../assets/emoticons/277.gif")
        //             },
        //             {
        //                 alt: '[发呆]',
        //                 src: require("../assets/emoticons/278.gif")
        //             },
        //             {
        //                 alt: '[愁]',
        //                 src: require("../assets/emoticons/279.gif")
        //             },
        //             {
        //                 alt: '[留下来]',
        //                 src: require("../assets/emoticons/280.gif")
        //             },
        //             {
        //                 alt: '[好困]',
        //                 src: require("../assets/emoticons/281.gif")
        //             },
        //             {
        //                 alt: '[困]',
        //                 src: require("../assets/emoticons/282.gif")
        //             },
        //             {
        //                 alt: '[榴莲]',
        //                 src: require("../assets/emoticons/283.gif")
        //             },
        //             {
        //                 alt: '[令我操碎了心]',
        //                 src: require("../assets/emoticons/284.gif")
        //             },
        //             {
        //                 alt: '[你骗人]',
        //                 src: require("../assets/emoticons/285.gif")
        //             },
        //             {
        //                 alt: '[不要走]',
        //                 src: require("../assets/emoticons/286.gif")
        //             },
        //             {
        //                 alt: '[笑死我了]',
        //                 src: require("../assets/emoticons/287.gif")
        //             },
        //             {
        //                 alt: '[背猪猪]',
        //                 src: require("../assets/emoticons/288.gif")
        //             },
        //             {
        //                 alt: '[太可怕]',
        //                 src: require("../assets/emoticons/289.gif")
        //             },
        //             {
        //                 alt: '[太skr]',
        //                 src: require("../assets/emoticons/290.gif")
        //             },
        //             {
        //                 alt: '[猫]',
        //                 src: require("../assets/emoticons/291.jpg")
        //             },
        //             {
        //                 alt: '[什么都不想做]',
        //                 src: require("../assets/emoticons/292.jpg")
        //             },
        //             {
        //                 alt: '[中邪]',
        //                 src: require("../assets/emoticons/293.jpg")
        //             },
        //             {
        //                 alt: '[先睡了]',
        //                 src: require("../assets/emoticons/294.jpg")
        //             },
        //             {
        //                 alt: '[鸡]',
        //                 src: require("../assets/emoticons/295.gif")
        //             },
        //             {
        //                 alt: '[让我看看]',
        //                 src: require("../assets/emoticons/296.gif")
        //             },
        //             {
        //                 alt: '[电梯]',
        //                 src: require("../assets/emoticons/297.gif")
        //             },
        //             {
        //                 alt: '[可以]',
        //                 src: require("../assets/emoticons/298.gif")
        //             },
        //             {
        //                 alt: '[戴眼镜]',
        //                 src: require("../assets/emoticons/299.gif")
        //             },
        //             {
        //                 alt: '[动图]',
        //                 src: require("../assets/emoticons/300.gif")
        //             }
        //         ]
        //     }
        // ];
        this.editor.customConfig.uploadImgServer = this.uploadUrl;
        //添加请求头
        this.editor.customConfig.uploadImgHeaders = {
            'Authorization': localStorage.getItem('token')
        }
        this.editor.customConfig.uploadFileName = "file";
        this.editor.customConfig.onchange = fn;
        this.editor.customConfig.zIndex = 100;
        this.editor.customConfig.uploadImgHooks = {
            before: function (xhr, editor, files) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size / 1024 / 1024 > 2) {
                        return {
                            prevent: true,
                            msg: '上传图片大小不能超过 2MB!'
                        }
                    }
                }
            },
        }
        if (menus.length) {
            this.editor.customConfig.menus = menus;
        } else {
            this.editor.customConfig.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                // 'emoticon',  // 表情
                'image',  // 插入图片
            ]
        }

        this.editor.create();
    }
    getWEHtml() {
        return this.editor.txt.html();
    }

    //发送完消息，清空文本域内容
    getNull() {
        return this.editor.txt.clear();
    }

    getWEText() {
        return this.editor.txt.text();
    }

    setWEHtml(val) {
        this.editor.txt.html(val)
    }
}

export default wEditor;